/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'query-string';
import LoginLayout from '../components/LoginLayout';
import { getTranslate } from 'react-localize-redux';
//import crypto from 'crypto';
import randomBytes from 'randombytes';
import Link from '../components/Link';
import { loginWithCredentials } from '../state/session';
import { hideAllNotifications } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form } from 'formik';
import { Button } from '../components';
import { Field, withFormik } from 'formik';
import CheckboxField from '../components/CheckboxField';
import Spinner from '../components/Spinner';
import { isBrowser, isAppleLoginEnabled } from '../utils';
import * as analytics from '../utils/analytics';

const loginSchema = translate =>
  yup.object().shape({
    username: yup
      .string()
      .min(1, translate('login.message.tooShort'))
      .required(translate('login.message.requiredField')),
    password: yup
      .string()
      .min(1, translate('register.message.tooShort'))
      .required(translate('register.message.requiredField')),
    rememberMe: yup.boolean().required(translate('register.message.requiredField')),
  });

const LoginForm = ({ translate, handleSubmit, isSubmitting, setFieldValue, setRememberMe }) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField name="username" label={translate('login.emailLabel')} />
      <FormField name="password" type="password" label={translate('login.passwordLabel')} />
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Field
          component={CheckboxField}
          onChange={e => {
            setRememberMe(e.target.checked);
            setFieldValue('rememberMe', e.target.checked);
          }}
          name="rememberMe"
          label={translate('login.rememberMeLabel')}
        />
        <Button sx={{ flex: 1, ml: 4 }} type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? (
            <Box sx={{ position: 'relative', height: 24 }}>
              <Spinner color="white" />
            </Box>
          ) : (
            translate('login.loginButton')
          )}
        </Button>
      </Flex>
    </Form>
  );
};

const LoginFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      username: vo.username || '',
      password: vo.password || '',
      rememberMe: vo.rememberMe || true,
    }),
  validationSchema: ({ translate }) => {
    return loginSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'LoginInputForm',
})(LoginForm);

const LoginPage = ({ location, pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const queryParams = queryString.parse(location.search);
  const { email, afterLogin } = queryParams;
  const translate = getTranslate(useSelector(state => state.localize));
  const [vo, setVO] = useState({ username: email });
  const [rememberMe, setRememberMe] = useState(true);
  const dispatch = useDispatch();

  //const { user } = useSelector(state => state.session);
  useEffect(() => {
    localStorage.setItem('srm', rememberMe);
  }, [rememberMe]);
  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(hideAllNotifications());
      dispatch(
        loginWithCredentials(vo.username, vo.password, vo.rememberMe, afterLogin, () => {
          localStorage.removeItem('srm');
          setSubmitting(false);
        })
      );
      setVO({});
    },
    [dispatch, afterLogin]
  );

  const formProps = { vo, onSubmit, translate, setRememberMe };
  const languageCode = pageContext.locale;
  const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';
  const mPakettiUrl = isTest ? 'https://extservicestest.matkahuolto.fi/' : 'https://extservices.matkahuolto.fi/';

  return (
    //    !user && (
    <LoginLayout title={translate('page.login')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <div sx={{ maxWidth: 480, m: 'auto' }}>
        <Styled.h1 sx={{ mt: 0 }}>{translate('page.loginTitle')}</Styled.h1>
        <LoginFormik {...formProps} />
      </div>
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          alignItems: 'center',
          justifyContent: 'center',
          mt: 4,
          mx: -10,
        }}
      >
        <SocialLoginButtons languageCode={pageContext.locale} afterLogin={afterLogin} />
      </Flex>
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          fontSize: [16, null, 15],
        }}
      >
        <Link to="/register" sx={{ mr: [0, 4], mb: [3, null, 0], textDecoration: 'underline' }}>
          {translate('login.registerButton')}
        </Link>
        <Link to="/request-password-reset" sx={{ mr: [0, 4], mb: [3, null, 0], textDecoration: 'underline' }}>
          {translate('page.resetPassword')}
        </Link>
        <Link to="/yrityksille" sx={{ textDecoration: 'underline' }} noTitleFix>
          {translate('page.loginCustomerInterface')}
        </Link>
      </Flex>
    </LoginLayout>
  );
};

const generateAppleLoginURL = state => {
  let apiUrl = process.env.GATSBY_API_URL;
  if (apiUrl.includes('localhost')) {
    apiUrl = 'https://ilm5tr64nd.execute-api.eu-west-1.amazonaws.com/dev';
  }

  return (
    'https://appleid.apple.com/auth/authorize?' +
    new URLSearchParams({
      response_type: 'code id_token',
      client_id: process.env.GATSBY_APPLE_CLIENT_ID,
      redirect_uri: apiUrl + '/apple/callback',
      state: state ? state : randomBytes(5).toString('hex'),
      scope: 'openid name email',
      response_mode: 'form_post',
    })
      .toString()
      .replace(/\+/g, '%20') // stupid Safari behaviour
  );
};

const SocialLoginButtons = ({ languageCode, afterLogin }) => {
  const oauthURL = `https://${process.env.AUTH_USER_POOL}/oauth2/authorize?`;
  const tokenPage = languageCode && languageCode !== 'en' ? `token-${languageCode}` : 'token';
  const authParams = {
    redirect_uri: `${process.env.GATSBY_WEB_URL}/${tokenPage}`,
    response_type: 'token',
    client_id: process.env.AUTH_APP_CLIENT_ID,
  };
  if (afterLogin) {
    authParams.state = JSON.stringify({ afterLogin });
  }
  const facebookLink =
    oauthURL +
    new URLSearchParams({
      ...authParams,
      identity_provider: 'Facebook',
    }).toString();
  const googleLink =
    oauthURL +
    new URLSearchParams({
      ...authParams,
      identity_provider: 'Google',
    }).toString();
  /* Cognito doesn't map name correctly
  const appleLink =
    oauthURL +
    new URLSearchParams({
      ...authParams,
      identity_provider: 'SignInWithApple',
    }).toString();
    */
  const appleLink = generateAppleLoginURL(authParams.state);

  return (
    <>
      <Flex
        as={isBrowser ? 'a' : 'span'}
        href={facebookLink}
        sx={{
          alignItems: 'center',
          mr: [0, 3],
          fontSize: [16, null, 15],
          textDecoration: 'underline',
        }}
      >
        <FacebookIcon />
        Login with Facebook
      </Flex>
      <Flex
        as={isBrowser ? 'a' : 'span'}
        href={googleLink}
        sx={{ alignItems: 'center', mr: [0, 3], fontSize: [16, null, 15], textDecoration: 'underline' }}
      >
        <GoogleIcon />
        Sign in with Google
      </Flex>
      {isAppleLoginEnabled && (
        <Flex
          as={isBrowser ? 'a' : 'span'}
          href={appleLink}
          sx={{ alignItems: 'center', fontSize: [16, null, 15], textDecoration: 'underline' }}
        >
          <AppleIcon />
          Sign in with Apple
        </Flex>
      )}
    </>
  );
};

const FacebookIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="a" d="M.001 0H20V19.88H.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h40v40H0z" />
      <g transform="translate(10 10)">
        <path
          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.879V12.89h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.989C16.343 19.129 20 14.99 20 10"
          fill="#1877F2"
          mask="url(#b)"
        />
      </g>
      <path
        d="M23.893 22.89l.443-2.89h-2.773v-1.876c0-.79.387-1.561 1.63-1.561h1.26v-2.461s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V20h-2.54v2.89h2.54v6.989a10.075 10.075 0 003.125 0V22.89h2.33"
        fill="#FFF"
      />
    </g>
  </svg>
);

const GoogleIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g>
        <rect width="40" height="40" rx="2" fill="#FFF" />
        <rect width="40" height="40" rx="2" />
        <rect width="40" height="40" rx="2" />
        <rect width="40" height="40" rx="2" />
      </g>
      <path
        d="M28.64 20.205c0-.639-.057-1.252-.164-1.841H20v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
        fill="#4285F4"
      />
      <path
        d="M20 29c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0020 29z"
        fill="#34A853"
      />
      <path
        d="M14.964 21.71a5.41 5.41 0 01-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0011 20c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        fill="#FBBC05"
      />
      <path
        d="M20 14.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C24.463 11.891 22.426 11 20 11a8.997 8.997 0 00-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71z"
        fill="#EA4335"
      />
      <path d="M11 11h18v18H11z" />
    </g>
  </svg>
);

const AppleIcon = () => (
  <svg width="40" height="44px" viewBox="0 0 31 44" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" x="0" y="0" width="31" height="44"></rect>
      <path
        d="M15.7099491,14.8846154 C16.5675461,14.8846154 17.642562,14.3048315 18.28274,13.5317864 C18.8625238,12.8312142 19.2852829,11.852829 19.2852829,10.8744437 C19.2852829,10.7415766 19.2732041,10.6087095 19.2490464,10.5 C18.2948188,10.5362365 17.1473299,11.140178 16.4588366,11.9494596 C15.9152893,12.56548 15.4200572,13.5317864 15.4200572,14.5222505 C15.4200572,14.6671964 15.4442149,14.8121424 15.4562937,14.8604577 C15.5166879,14.8725366 15.6133185,14.8846154 15.7099491,14.8846154 Z M12.6902416,29.5 C13.8618881,29.5 14.3812778,28.714876 15.8428163,28.714876 C17.3285124,28.714876 17.6546408,29.4758423 18.9591545,29.4758423 C20.2395105,29.4758423 21.0971074,28.292117 21.9063891,27.1325493 C22.8123013,25.8038779 23.1867451,24.4993643 23.2109027,24.4389701 C23.1263509,24.4148125 20.6743484,23.4122695 20.6743484,20.5979021 C20.6743484,18.1579784 22.6069612,17.0588048 22.7156707,16.974253 C21.4353147,15.1382708 19.490623,15.0899555 18.9591545,15.0899555 C17.5217737,15.0899555 16.3501271,15.9596313 15.6133185,15.9596313 C14.8161157,15.9596313 13.7652575,15.1382708 12.521138,15.1382708 C10.1536872,15.1382708 7.75,17.0950413 7.75,20.7911634 C7.75,23.0861411 8.64383344,25.513986 9.74300699,27.0842339 C10.6851558,28.4129053 11.5065162,29.5 12.6902416,29.5 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default LoginPage;
